<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">答题红包活动列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="活动区划" class="searchboxItem ci-full">
              <span class="itemLabel">活动区划:</span>
              <el-cascader
                v-model="areaId"
                :options="areaTreeList"
                :props="propsArea"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </div>
            <div title="主办方" class="searchboxItem ci-full">
              <span class="itemLabel">主办方:</span>
              <el-input
                v-model="organiser"
                clearable
                placeholder="请输入主办方"
                size="small"
              ></el-input>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="addActivity('add', activityId, false)"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="活动名称"
                align="left"
                show-overflow-tooltip
                prop="activityName"
                min-width="200"
              />
              <el-table-column
                label="活动时间"
                align="left"
                show-overflow-tooltip
                width="300"
              >
                <template slot-scope="scope">
                  {{ scope.row.startDate }}～{{ scope.row.endDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="主办方"
                align="center"
                show-overflow-tooltip
                prop="organiser"
                width="200"
              />
              <el-table-column
                label="活动区划"
                align="center"
                show-overflow-tooltip
                prop="areaNamePath"
                min-width="200"
              />
              <el-table-column
                label="状态"
                align="center"
                show-overflow-tooltip
                prop="activityStatus"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("ACTIVITY_STATUS", scope.row.activityStatus)
                  }}
                </template>
              </el-table-column>
               <el-table-column
                label="是否发布"
                align="center"
                show-overflow-tooltip
                prop="isPublish"
              >
                <template slot-scope="scope">
                  {{
                   scope.row.isPublish? "已发布" : "未发布"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建人"
                align="center"
                show-overflow-tooltip
                prop="fullname"
                min-width="150"
              />
              <el-table-column
                label="创建日期"
                align="center"
                show-overflow-tooltip
                prop="createTime"
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                align="center"
                width="320px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="
                      addActivity(
                        'edit',
                        scope.row.activityId,
                        scope.row.isPublish
                      )
                    "
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="scope.row.isPublish"
                    @click="deleteInfo(scope.row.activityId)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="scope.row.isPublish"
                    @click="publishActivity(scope.row.activityId)"
                    >发布</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="scope.row.activityStatus == '40'"
                    @click="endActivity(scope.row.activityId)"
                    >终止</el-button
                  >
                  <el-button
                  　id="copy_text"
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="!scope.row.isPublish"
                    data-clipboard-action="copy"
  　　　　　　:data-clipboard-text="copy"
                    @click="coptLen(scope.row.activityId)"
                    >复制链接</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="CheckTheCollection(scope.row.activityId)"
                    >查看领取情况</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import Clipboard from "clipboard";
export default {
  name: "activeConfigurationList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      organiser: "", // 主办方
      areaId: "", //行政区划
      areaTreeList: [], // 行政区划数据
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      code:'/pages/pageB/redEnvelopes_Notice/index',
      copy:'',
    };
  },
  watch: {},
  created() {
    this.getAreaTree();
  },
  computed: {},
  mounted() {},
  methods: {
    //获取行政区划
    getAreaTree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areaTreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 初始化获取评论列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.organiser) {
        params.organiser = this.organiser;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      this.doFetch({
        url: "/biz/activity/pageList",
        params,
        pageNum,
      });
    },
    //新增||编辑
    addActivity(stu, activityId = "", isPublish) {
      this.$router.push({
        path: "/web/workOrder/activeConfigurationAdd",
        query: {
          getClassInfo: JSON.stringify({
            stu,
            activityId,
            isPublish,
          }),
        },
      });
    },

    //删除
    deleteInfo(activityId) {
      this.doDel({
        url: "/biz/activity/delete",
        msg: "你确定要删除该活动吗？",
        ps: {
          type: "post",
          data: { activityId },
        },
      });
    },
    //终止活动
    endActivity(activityId) {
      this.doDel({
        url: "/biz/activity/termination",
        msg: "你确定要终止该活动吗？",
        ps: {
          type: "post",
          data: { activityId },
        },
      });
    },
    //发布
    publishActivity(activityId) {
      this.doDel({
        url: "/biz/activity/publish",
        msg: "你确定要发布该活动吗？",
        ps: {
          type: "post",
          data: { activityId },
        },
      });
    },
    //查看领取情况
    CheckTheCollection(activityId) {
      this.$router.push({
        path: "/web/workOrder/activeCheckTheCollection",
        query: {
          activityId,
        },
      });
    },
      coptLen(activityId) { 
        let _this = this
           _this.copy =_this.code+ '?activityId='+ activityId
	      let  clipboard = new Clipboard("#copy_text");
	      clipboard.on('success', function (val) {
	        // __this.disabled = true
	        setTimeout(() => {
	        //   __this.disabled = false
	          // 销毁实例防止多次触发
	          clipboard.destroy()
	        }, 1000)
            _this.$notify({
          title: '提示',
          message: '恭喜您复制成功，赶快去粘贴吧~',
          type: 'success'
        });
	      })
	      clipboard.on('error', function () {
	        _this.$message.error('复制失败，请手动复制')
	      })
        },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
</style>